@use 'theme.scss';

h1,h2,h3,h4,h5,h6{
  margin-top: 20px;
}

a {
  text-decoration: none;
  color: black;
}

html {
}

nav {
  padding: 0;
  margin: 0;
}

.layout_articles {
  padding: 0;
  margin: 0;
}


nav ul {
  list-style: none;
}

.MainTitle {
  font-size: 4em;
  margin: 0;
  padding: 20px;
  color: theme.$main-accent-color;
  text-shadow: 1px 1px 1px black, 2px 2px 2px black, 3px 3px 3px black, 4px 4px 4px black,-1px -1px 1px theme.$secondary-accent-color;
}


.App{
}


.ContentView {
  /*position: relative;*/
  display: flex;
  flex-direction: row;
}

.MainContentView {
  /*width: 80%;*/
  margin: 0 auto;
}
.Sidebar{
  position: sticky;
  top: 32px;
  height: calc(100vh - 32px);
  width: 20%;
  background: white;
  overflow: hidden;
}

.containerSlide {
  min-height: 0px;
  min-width: 0px;
}

.containerSlide img {
  width: 80%;
}

.footer{
  margin-top: 15px;
}

.footer p{
  margin: 0;
  padding: 5px;
  color: theme.$title-accent-color;
  background: theme.$main-accent-color;
}
