@use 'src/theme';

.article-card {
  border-radius: 5vh;
  //width: 90vw;
  //min-width: 350px;
  margin: 30px auto;
}

.article-card .preview {
  display: flex;
  flex-direction: column;
  @media screen and (width < 768px) {
    flex-direction: column;
  }
}

.article-card .poster {
  border-radius: 10px;
  margin: 1px;
  max-width: 100%;
  height: auto;
}

.article-card:hover {
  background-color: theme.$background-color;
}

.article-card .title {
  text-shadow: 1px 1px 1px theme.$main-accent-color;
  font-size: 2em;
  margin: 16px;
  color: theme.$secondary-accent-color
}

.article-card .description {
  display: flex;
  flex-direction: column;
  justify-content: start;
  @media screen and (max-width: 768px){
    font-size: 0.75em;
  }
}

.article-card p {
  text-align: justify;
  padding: 1px;
  margin: 10px;
  overflow: auto;
}

.article-card a {
  text-align: center;
  padding: 2px;
  margin: 2px auto;
  color: white;
  width: 100px;
  background-color: theme.$secondary-accent-color;
  border-radius: 5px;
}