@use 'src/theme';

.Menu {
  z-index: 21;
  position: sticky;
  top: 0;
  padding: 0 20px;
  background-color: theme.$main-accent-color;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  //height: 30px;
  justify-content: center;
  column-gap: 40px;
  align-items: center;
}

.MainMenu {
  min-height: 30px;
  @media screen and (max-width: 768px){
    flex-direction: column;
  }
}

.SubMenu {
  display: flex;
  top: 30px;
  border: 1px solid black;
}

.Menu .icon{
  display: none;
}

@media screen and (max-width: 768px){
  .MainMenu a:not(.active) {
    display: none;
  }
  .MainMenu svg.icon{
    display: block;
    float: right;
    position: absolute;
    top: 2px;
    right: 5px;
    filter: drop-shadow(2px 2px 1px rgb(0 0 0 / 1.0));
  }
  //
  //.SubMenu {
  //  flex-direction: column;
  //}
}


.Menu a, .MainMenu svg.icon {
  //display: inline-block;
  text-decoration: none;
  font-size: 24px;
  color: theme.$title-accent-color;
  font-weight: bold;
  text-shadow: 1px 1px 1px black, 2px 2px 2px black, -1px -1px 1px black,-1px 1px 1px black,1px -1px 1px black;
}


.Menu .active {
  color: theme.$main-accent-color;
  font-weight: bold;
}
